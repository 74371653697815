//* * this file should be modified only by you */
// import type { WritableComputedRef } from 'vue'
import type { CustomStoreImplementation, CustomStoreImplementationInstance } from 'kapix-components-vue3'
import { navigateTo, useToast } from 'kapix-components-vue3'
import type { StateTree, _GettersTree } from 'pinia'
import type { headerLayoutRaw } from './store'
import { useAuthStore } from '~/stores/auth'

type HeaderStore = ReturnType<typeof headerLayoutRaw>
function defineCustomStoreImplementation<S extends StateTree = {}, G extends _GettersTree<S> = {}, A = {}> (options: CustomStoreImplementation<HeaderStore, S, G, A>) {
  return {
    instance: options as CustomStoreImplementationInstance<HeaderStore, S, G, A>,
    options
  }
}

export default defineCustomStoreImplementation({
  state: () => {
    return {
      // custom state here
    }
  },
  getters: {
    // // ** EXAMPLE ** //
    // myCustomGetter (): WritableComputedRef<Nullable<string>> {
    //   return computed({
    //     get: () => this.$title,
    //     set: (value) => {
    //       this.$title = value
    //     }
    //   })
    // }
  },
  actions: {
    async customPressCoDecoButton () {
      const authStore = useAuthStore()
      const toast = useToast()
      if (authStore.isUserLoggedIn) {
        const { error } = await authStore.logout()
        if (error) toast.error(`Erreur de déconnexion : ${error.message}`)
      }
      else navigateTo({ name: 'Connexion' })
    },
    beforeMount () {
    },
    async mounted () {
      // // ** EXAMPLE ** //
      // // custom action like fetching data
      // const response = await axios.get('https://backend-url/my-api/endpoint')
      // this.$emailInput = response.data.email
    },
    beforeUnmount () {
    },
    unmounted () {
    }
  }
})
