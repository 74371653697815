import { defineStore } from 'pinia'
import type { CustomStoreImplementationOptions, KaElementClickEvent, KaElementMap, MergeCustomStore, StoreIdentifier } from 'kapix-components-vue3'
import { initAliveStoreIds, kapixContext, navigateTo, remove, scrollInfo } from 'kapix-components-vue3'
import headerLayoutStoreCustom from './store.custom'
import HautDePage from '~/menus/HautDePage/index.vue'
import MenuEntreprise from '~/menus/MenuEntreprise/index.vue'
import MenuExpert from '~/menus/MenuExpert/index.vue'
import MenuFormations from '~/menus/MenuFormations/index.vue'
import { $translate } from '~/modules/i18n'

const storeName = 'headerLayout'
const customImplement: CustomStoreImplementationOptions = headerLayoutStoreCustom.options
const aliveStoreIds = initAliveStoreIds()

function headerLayoutFactory (storeId?: Nullable<StoreIdentifier>) {
  return defineStore(storeId == null ? storeName : `${storeName}/${storeId}`, {
    state: () => {
      return {
        $aliveStoreIds: aliveStoreIds,
        $subStoreIds: 1,
        $storeId: storeId,
        $emit: (() => {}) as (propName: any, value: any) => void,
        $elements: {} as KaElementMap,
        $router: kapixContext.isClient ? kapixContext.$router : undefined,
        $route: kapixContext.isClient ? kapixContext.$route : undefined,
        // Page info,
        $title: $translate('header.title', 'header'),
        $description: $translate('header.description', ''),
        $publishedTime: new Date('2023-02-13T07:42:17.388Z'),
        $modifiedTime: new Date('2023-11-03T13:16:54.377Z'),
        // Constants,
        $constants: { bandeTemporaireHidden: null },
        // Data,
        $info: { header: scrollInfo() },
        ...(customImplement.state && customImplement.state())
      }
    },
    getters: {
      ...customImplement.getters
    },
    actions: {
      /* @ts-ignore: to allow override in your custom file */
      async logoDeLentrepriseClick () {
        await navigateTo({ path: '/' })
      },
      /* @ts-ignore: to allow override in your custom file */
      headerOngletClick (event: KaElementClickEvent) {
        event.vm?.showTooltip(MenuEntreprise)
      },
      /* @ts-ignore: to allow override in your custom file */
      element1Click (event: KaElementClickEvent) {
        event.vm?.showTooltip(MenuExpert)
      },
      /* @ts-ignore: to allow override in your custom file */
      element2Click (event: KaElementClickEvent) {
        event.vm?.showTooltip(MenuFormations)
      },
      /* @ts-ignore: to allow override in your custom file */
      async ongletAProposClick () {
        await navigateTo({ name: 'Infos' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async contactClick () {
        await navigateTo({ name: 'Contact' })
      },
      /* @ts-ignore: to allow override in your custom file */
      menuBurgerClickOnXs (event: KaElementClickEvent) {
        event.vm?.showTooltip(HautDePage)
      },
      /* @ts-ignore: to allow override in your custom file */
      menuBurgerClickOnXsWhenSelected (event: KaElementClickEvent) {
        event.vm?.closeAllTooltips()
      },
      /* @ts-ignore: to allow override in your custom file */
      iconClick () {
        this.$elements['bande-temporaire'].style = { hidden: this.$constants.bandeTemporaireHidden }
      },
      /* @ts-ignore: to allow override in your custom file */
      async MatchersLeve2MClick () {
        await navigateTo({ name: 'Histoire' })
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        return storeId != null ? getStoreInstance(storeId) : this
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstances () {
        return aliveStoreIds.map(storeId => this.getStoreInstance(storeId))
      },
      /* @ts-ignore: to allow override in your custom file */
      newStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        const newStoreId = storeId || this.$subStoreIds++
        if (aliveStoreIds.includes(newStoreId)) {
          throw new Error(`Store with id ${storeId} already exists`)
        }
        aliveStoreIds.push(newStoreId)
        return getStoreInstance(newStoreId)
      },
      ...customImplement.actions,
      /* @ts-ignore: to allow override in your custom file */
      beforeMount () {
        customImplement.actions?.beforeMount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      mounted () {
        customImplement.actions?.mounted?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      async serverPrefetch () {
        await customImplement.actions?.serverPrefetch?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      beforeUnmount () {
        customImplement.actions?.beforeUnmount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      unmounted () {
        if (this.$storeId != null) {
          this.$dispose()
          remove(aliveStoreIds, this.$storeId)
        }
        customImplement.actions?.unmounted?.call(this)
      }
    }
  })
}

function getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
  return headerLayoutFactory(storeId)()
}

export const headerLayoutRaw = headerLayoutFactory()
export const headerLayout = () => headerLayoutRaw() as MergeCustomStore<typeof headerLayoutStoreCustom.instance>
